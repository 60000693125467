import { render, staticRenderFns } from "./default.vue?vue&type=template&id=5cca6322&scoped=true"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=5cca6322&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cca6322",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderDesktop: require('/home/vsts/work/1/s/components/header/header-desktop.vue').default,HeaderMobile: require('/home/vsts/work/1/s/components/header/header-mobile.vue').default,FooterDesktop: require('/home/vsts/work/1/s/components/footer/footer-desktop.vue').default,FooterMobile: require('/home/vsts/work/1/s/components/footer/footer-mobile.vue').default})
