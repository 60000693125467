
import { mapGetters } from 'vuex'
import SvgFlagCa from '~/assets/icons/flag-ca.svg?inline'
import SvgFlagUs from '~/assets/icons/flag-us.svg?inline'
import SvgFlagEu from '~/assets/icons/flag-eu.svg?inline'
export default {
  components: {
    SvgFlagCa,
    SvgFlagUs,
    SvgFlagEu,
  },
  props: {
    currencyDetectChange: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      vxIsScreenLarge: 'responsive/getIsWindowLarge',
      vxCurrency: 'currency/getCurrency',
      vxWording: 'common/getSwitchLocator',
    }),
    modalWordingHeader() {
      return this.vxWording.popup_updateCurrency
    },
    modalWordingText() {
      return this.vxWording.popup_text?.replace('{{currency}}', this.vxCurrency)
    },
  },
}
