
export default {
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    itemActive: 1,
  }),
  computed: {
    tabs() {
      const lists = []
      for (let i = 1; i <= 6; i++) {
        const name = this.blok[`name_list_${i}`]
        const list = this.blok[`list_${i}`]
        if (name && list?.length > 0) lists.push({ name, list })
      }
      return lists
    },
  },
}
