export const parseCommon = (stories) => {
  const keyNames = {
    footer: 'footer',
    error_code: 'errorStory',
    change_location: 'switchLocator',
    signup_newsletter: 'signupNewsletter',
    search_bar: 'searchStory',
    recently_viewed: 'recentlyViewed',
    notification_snackbar: 'snackbarsStory',
    top_bar_switch_location: 'topBarSwitch',
    surheader_banner: 'subHeader',
    flaps: 'productsFlaps',
    errors_form: 'errorsForm',
    notification_confirmation: 'confirmationStory',
    navigation: 'navigationStory',
    menu: 'menuStory',
    'Collection Page': 'search2Story',
    'Banners collection page': 'bannersStory',
    'Category Left Menu': 'categoryStory',
    'Carousel colors filter': 'carouselColorsFilter',
    size_guide: 'sizeGuide',
    helpers_input_password: 'helpersInputPassword',
  }
  const results = stories.reduce((acc, { name, content }) => {
    const key = keyNames[name]
    if (key) acc[key] = content
    return acc
  }, {})
  return results
}
