import * as Sentry from '@sentry/vue'

export const state = () => ({
  customer: null,
  customerAccessToken: null,
})

export const getters = {
  getCustomer(state) {
    return state.customer
  },
  getCustomerAccessToken(state) {
    return state.customerAccessToken
  },
  getBasicCustomer(state) {
    return state.customer
      ? {
          firstName: state.customer.firstName,
          lastName: state.customer.lastName,
          email: state.customer.email,
        }
      : null
  },
}

export const mutations = {
  setCustomer(state, customer) {
    state.customer = customer
  },
  setCustomerAccessToken(state, customerAccessToken) {
    state.customerAccessToken = customerAccessToken
  },
}

export const actions = {
  async initialize({ commit, dispatch, getters }) {
    try {
      const shopifyCookie = this.app.$cookies.get('lole-customer')
      if (shopifyCookie) {
        const renewedAccess =
          await this.app.$shopify.customer.customerAccessTokenRenew(
            shopifyCookie.accessToken
          )
        commit('setCustomerAccessToken', renewedAccess)
        await dispatch('retrieveCustomer')
        // LOYALTY
        await dispatch('loyalty/init', getters.getCustomer, { root: true })
        // KLAVIYO
        await dispatch('klaviyo/init', getters.getCustomer, { root: true })
      }
    } catch (e) {
      this.app.$cookies.remove('lole-customer')
    }
  },
  async login({ commit, dispatch, getters }, { email, password }) {
    try {
      const customerAccessToken =
        await this.app.$shopify.customer.customerAccessTokenCreate({
          email,
          password,
        })
      this.app.$cookies.set('lole-customer', customerAccessToken, {
        expires: new Date(customerAccessToken.expiresAt),
        path: '/',
      })
      commit('setCustomerAccessToken', customerAccessToken)
      await dispatch('retrieveCustomer')

      // SENTRY SET USER
      Sentry.setUser({
        email: getters.getCustomer.email,
        id: getters.getCustomer.id,
      })
      // LOYALTY
      await dispatch('loyalty/init', getters.getCustomer, { root: true })
      // KLAVIYO
      await dispatch('klaviyo/init', getters.getCustomer, { root: true })
    } catch (e) {
      throw new Error(e)
    }
  },
  async logout({ commit, dispatch, getters }) {
    try {
      commit('setCustomer', null)
      commit('setCustomerAccessToken', null)

      this.app.$cookies.remove('lole-customer')
      dispatch('cart/clear', null, { root: true })
      dispatch('loyalty/clear', {}, { root: true })
      // SENTRY REMOVE USER
      Sentry.setUser(null)
      const accessToken = getters.getCustomerAccessToken
      if (accessToken)
        await this.app.$shopify.customer.customerAccessTokenDelete(accessToken)
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'STORE customer',
        level: 'fatal',
        extra: 'ERROR STORE - customer/logout(): ' + e,
      })
    }
  },
  async createCustomer({ commit, dispatch }, input) {
    try {
      const { customer } = await this.app.$shopify.customer.customerCreate(
        input
      )
      if (!customer) throw new Error('account creation failed')
      if (this.app.i18n.locale === 'fr') {
        await this.$axios.post('/api/shopify/updateCustomerLocale', {
          input: {
            id: customer?.id,
            locale: 'fr',
          },
        })
      }
      const customerAccessToken =
        await this.app.$shopify.customer.customerAccessTokenCreate({
          email: input.email,
          password: input.password,
        })
      this.app.$cookies.set('lole-customer', customerAccessToken, {
        expires: new Date(customerAccessToken.expiresAt),
        path: '/',
      })
      commit('setCustomerAccessToken', customerAccessToken)
      await dispatch('retrieveCustomer')
      // LOYALTY
      await dispatch('loyalty/init', getters.getCustomer, { root: true })
      // KLAVIYO
      await dispatch('klaviyo/init', getters.getCustomer, { root: true })
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'STORE customer',
        level: 'fatal',
        extra: 'ERROR STORE - customer/createCustomer(): ' + e,
      })
      throw new Error(e)
    }
  },
  async recoverCustomer({ commit }, email) {
    try {
      await this.app.$shopify.customer.customerRecover(email)
    } catch (e) {
      throw new Error(e)
    }
  },
  async activateCustomer({ commit }, input) {
    try {
      await this.app.$shopify.customer.customerActivate(
        input.id,
        input.token,
        input.password
      )
    } catch (e) {
      throw new Error(e)
    }
  },
  async resetCustomer({ commit }, input) {
    try {
      await this.app.$shopify.customer.customerReset(
        input.id,
        input.token,
        input.password
      )
    } catch (e) {
      throw new Error(e)
    }
  },
  async updateCustomer({ commit, getters }, input) {
    try {
      const { accessToken } = getters.getCustomerAccessToken
      const { customer } = await this.app.$shopify.customer.customerUpdate(
        accessToken,
        input
      )
      commit('setCustomer', customer)
    } catch (e) {
      throw new Error(e)
    }
  },
  async retrieveCustomer({ getters, commit, dispatch }) {
    try {
      const { accessToken } = getters.getCustomerAccessToken
      const customer = await this.app.$shopify.customer.retrieveCustomer(
        accessToken
      )
      commit('setCustomer', customer)
      dispatch('customer/orders/getOrdersStatus', null, { root: true })
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'STORE customer',
        level: 'fatal',
        extra: 'ERROR STORE - customer/retrieveCustomer(): ' + e,
      })
    }
  },
}
