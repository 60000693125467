export const state = () => ({
  // CAD, USD, EUR
  currency: null,
  thirty_days: 60 * 60 * 24 * 30,
})

export const getters = {
  getCurrency: (state) => state.currency,
}

export const mutations = {
  setCurrency(state, currency) {
    state.currency = currency
  },
}

export const actions = {
  initialize({ commit }) {
    try {
      const locale = this.app?.i18n?.locale || 'en'
      const routeQuery = this.app?.context?.route?.query || {}
      const acceptedCurrencies = { CAD: true, USD: true, EUR: true }
      let currency = 'USD'

      // IF CURRENCY IS SET IN URL PARAMS
      const currencyFromUrl = acceptedCurrencies?.[
        routeQuery?.currency?.toUpperCase()
      ]
        ? routeQuery.currency
        : null

      // IF CURRENCY IS SET IN COOKIES
      const currencyOverride = this.app.$cookies.get('lole-currency-ovr')
      const currencyAuto = this.app.$cookies.get('lole-currency-auto')

      if (currencyFromUrl) {
        currency = currencyFromUrl
      } else if (currencyOverride) {
        currency = currencyOverride
      } else if (currencyAuto) {
        currency = currencyAuto
      }

      currency = currency.toUpperCase()

      // DEFAULT TO ENGLISH IF CURRENCY IS USD
      if (currency === 'USD' && locale === 'fr') {
        this.app.$cookies.remove(`lole_i18n`)
        this.app.$cookies.set(`lole_i18n`, 'en')
        this.app.i18n.setLocale('en')
      }
      commit('setCurrency', currency)
    } catch (e) {
      this.app.$cookies.remove('lole-currency-ovr')
    }
  },
  async switchCurrency({ commit, state }, currency) {
    await this.app.$cookies.set('lole-currency-ovr', currency, {
      maxAge: state.thirty_days,
      path: '/',
    })
    commit('setCurrency', currency)
  },
}
