export const state = () => ({
  profile: null,
})

export const getters = {
  getProfile(state) {
    return state.profile
  },
}

export const mutations = {
  setProfile(state, profile) {
    state.profile = profile
  },
  setPreferences(state, preferences) {
    if (state.profile)
      state.profile.attributes.properties['Product Preference'] = preferences
  },
}

export const actions = {
  async init({ getters, commit }, customer) {
    try {
      const profile = getters.getProfile
      if (!profile && customer && customer.email) {
        const { data } = await this.$axios
          .post('/api/klaviyo/getProfile', {
            email: customer.email,
          })
          .catch((error) => {
            return error
          })
        if (data) commit('setProfile', data)
      }
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'STORE - Klaviyo init()',
        level: 'warning',
        extra: 'Error init():' + e,
      })
      throw new Error(e)
    }
  },
  async subscribe({ rootGetters }, { email, source }) {
    try {
      const company_id = this.$config.KLAVIYO.PUB_API_KEY
      const list_id =
        this.$config.KLAVIYO.LIST_ID[this.$i18n.locale.toUpperCase()]
      const currency = rootGetters['currency/getCurrency']
      let country = null
      switch (currency) {
        case 'USD':
          country = 'United-States'
          break
        case 'CAD':
          country = 'Canada'
          break
        case 'EUR':
          country = 'Europe'
          break
        default:
          country = null
          break
      }
      return await this.$axios
        .post(
          `https://a.klaviyo.com/client/subscriptions/?company_id=${company_id}`,
          {
            data: {
              type: 'subscription',
              attributes: {
                profile: {
                  data: {
                    type: 'profile',
                    attributes: {
                      email,
                      properties: {
                        country,
                      },
                    },
                  },
                },
                custom_source: source,
              },
              relationships: { list: { data: { type: 'list', id: list_id } } },
            },
          },
          {
            headers: {
              revision: '2024-07-15',
              'content-type': 'application/json',
            },
          }
        )
        .then((res) => {
          return res.status === 202
        })
        .catch((error) => {
          throw new Error(error)
        })
    } catch (e) {
      throw new Error(e)
    }
  },
  async updateInterests({ commit }, { id, interests }) {
    try {
      const { data } = await this.$axios
        .post('/api/klaviyo/updatePreferences', {
          id,
          preferences: interests,
        })
        .catch((error) => {
          return error
        })
      commit('setPreferences', data)
    } catch (e) {
      throw new Error(e)
    }
  },
}
